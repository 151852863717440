import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from '../types';
import { filterDataByLocale, resolveLocale } from '@sprinklr/shared-lib/utils';
import MainPageTemplate from '../mainPage';

const CustomerStoriesIndexPageTemplate: React.FC<PageProps> = props => {
  const { data, pageContext } = props;
  const { nodeLocale } = pageContext;
  const customData = getCustomerStoriesData(data, nodeLocale);

  const passedProps = {
    ...props,
  };
  passedProps.customData = customData;
  passedProps.data = {
    contentfulPage: data.contentfulPage,
    site: data.site,
    headerV3: data.headerV3,
    footerV3: data.footerV3,
    allContentfulGlobalHeaderFooter: data.allContentfulGlobalHeaderFooter,
  };

  passedProps.pageContext = {
    ...pageContext,
    isGradientBackgroundEnabled: nodeLocale === 'en-US',
  };

  return <MainPageTemplate passedProps={passedProps} />;
};

export const customerStoryPageQuery = graphql`
  query CustomerStoryPageSlug(
    $slug: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $language: String!
  ) {
    ...HeaderFooterSocialQuery
    ...PageQuery
    ...LocalesQuery
    ...SiteMetadataQuery
    customerStoriesAllPage: allContentfulPage(
      filter: {
        categorySlug: { eq: "stories" }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        pageContent {
          templates {
            ... on ContentfulTemplateCustomerStory {
              ...ContentfulTemplateCustomerStoryNode
            }
          }
        }
        navSlug
        nodeLocale: node_locale
        translations {
          languagesToRenderIn
        }
      }
    }
    customerStoriesAllTemplates: allContentfulTemplateCustomerStory(
      filter: { storyCardOpensPdfDirectly: { eq: true } }
    ) {
      nodes {
        nodeLocale: node_locale
        ...ContentfulTemplateCustomerStoryNode
      }
    }
    sitePage {
      context {
        language
        navSlug
        nodeLocale
        slug
      }
    }
  }
`;

export default CustomerStoriesIndexPageTemplate;

const getCustomerStoriesData = (data, locale) => {
  //may be able to delete this
  const newCustomerStoriesWithNoPageNodes =
    data?.customerStoriesAllTemplates?.nodes ?? [];
  const localizedNewCustomerStoriesWithNoPageNodes = filterDataByLocale(
    newCustomerStoriesWithNoPageNodes,
    locale,
    'additionalLanguagesToRenderIn',
    true,
    true,
  );
  const newCustomerStoriesWithNoPage =
    localizedNewCustomerStoriesWithNoPageNodes.map(story =>
      formatNewStoryCards(story, null),
    );

  const newStoryCardNodes = data?.customerStoriesAllPage?.nodes ?? [];
  const localizedNewStoryCardNodes = filterDataByLocale(
    newStoryCardNodes,
    locale,
    'additionalLanguagesToRenderIn',
    false,
    true,
  );
  const newStoryCards = localizedNewStoryCardNodes
    .map(node => {
      node.templates = node?.pageContent?.templates?.filter(
        template => template.__typename === 'ContentfulTemplateCustomerStory',
      );
      return node;
    })
    .filter(
      node =>
        !!node?.templates && !node?.templates[0]?.storyCardOpensPdfDirectly,
    )
    .filter(node => Boolean(node?.templates[0]))
    .map(node => formatNewStoryCards(node?.templates[0], node));

  return [...newStoryCards, ...newCustomerStoriesWithNoPage].sort(
    (a, b) => b.sortByDate - a.sortByDate,
  );
};

const formatNewStoryCards = (card, node) => {
  const {
    id,
    name: title,
    heroMedia,
    downloadUrl,
    sortByDate,
    shortDescription: description,
    storyCardOpensPdfDirectly,
    nodeLocale: localeProp,
    heroMediaType,
    heroVideoPreviewImage,
  } = card;

  const nodeLocale = node ? node.nodeLocale : localeProp ? localeProp : '';
  const isTranslated = node?.translations?.languagesToRenderIn?.some(
    translation => translation.includes(node.nodeLocale),
  );

  const previewImage =
    heroMediaType === 'VIDEO' ? heroVideoPreviewImage : heroMedia?.asset;
  const languagePrefix = isTranslated ? resolveLocale(nodeLocale) : '';
  const slug =
    storyCardOpensPdfDirectly || languagePrefix
      ? `/${languagePrefix}/stories/${node?.navSlug}`
      : `/stories/${node?.navSlug}`;

  return {
    id,
    title,
    description,
    previewImage: previewImage,
    externalMedia: storyCardOpensPdfDirectly && { file: { url: downloadUrl } },
    slug,
    sortByDate: new Date(sortByDate).getTime(),
    nodeLocale,
    translations: node?.translations,
  };
};
